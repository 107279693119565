import React, {useMemo} from 'react';

import styles from './style.module.css';
import {Box, Paper, Stack, Typography} from "@mui/material";
import moment from 'moment'
import {motion} from 'framer-motion'
import {useQuery} from "@apollo/client";
import {NEXT_STREAMS_QUERY} from "../../../apollo/queries/next-streams";

const StreamsLayout = (props) => {
  const {data} = useQuery(NEXT_STREAMS_QUERY, {
    pollInterval: 2 * 60 * 1000,
    variables: {amount: props.props.streamCount}
  });

  const streams = useMemo(() => {
    if (!data) return [];
    return data.nextStreams?.map(s => Object.assign({}, s, {
      time: moment(new Date(s.startTime)),
      image: s.category?.imageUrl
    })) || [];
  }, [data]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.18,
      }
    }
  }

  const item = {
    hidden: {x: -50, y: 100, rotateY: 70, rotateX: 8, opacity: 0},
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      rotateY: 0,
      rotateX: 0,
    }
  }

  const renderDay = (time) => {
    if (moment(time).startOf('day').isSame(moment().startOf('day'))) {
      return "Heute"
    } else if (moment(time).startOf('day').isSame(moment().add(1, 'days').startOf('day'))) {
      return "Morgen"
    } else {
      return moment(time).format('dddd')
    }

  }

  const renderStreams = () => {
    const arr = streams.slice(0, props?.props.streamCount);
    return arr.map((stream, index) => (
      <motion.div
        key={index}
        variants={item}
        transition={{duration: 1.8, ease: [.31,.07,0,1]}}
      >
        <Paper className={index === 0 ? styles.streamCardFirst : styles.streamCard}>
          <Box p={3}>
            <Stack direction={'column'} spacing={5}>
              <Stack direction={'column'} spacing={1}>
                <Typography className={styles.text} textAlign={'center'}
                            variant={'h1'}>{renderDay(stream.time)}</Typography>
                <Typography className={styles.text} textAlign={'center'}
                            variant={'h4'}>{moment(stream.time).format('HH') + " Uhr"}</Typography>
              </Stack>
              <Box className={styles.gameImageContainer}>
                <img alt={'Game'} src={stream.image} className={styles.gameImage}/>
                <img alt={'Game'} src={stream.image}
                     className={index === 0 ? styles.gameImageShadowFirst : styles.gameImageShadow}/>
              </Box>
              <Typography className={styles.text} textAlign={'center'} variant={'h1'}>{stream.title}</Typography>
            </Stack>
          </Box>
        </Paper>
      </motion.div>
    ))
  }

  return (
    <Box height={'100%'} display={'grid'} alignContent={'center'} justifyItems={'center'}>
      <Stack direction={'column'} spacing={5}>
        <Typography variant={'h3'} textAlign={'center'}>Die nächsten Streams...</Typography>
        <motion.div
          variants={container}
          initial="hidden"
          animate="show">
          <Stack
            direction={'row'}
            spacing={3}
            justifyContent={'center'}>
            {renderStreams()}
          </Stack>
        </motion.div>
      </Stack>
    </Box>
  )
}

export default StreamsLayout;
