import React from 'react';

import styles from './style.module.css';
import {Box, Paper, Stack, Typography} from "@mui/material";
import {mmss} from "../../../time";
import {motion} from "framer-motion";

const StartLayout = (props) => {
    return(
        <Box height={'100%'} display={'grid'} alignContent={'center'} justifyItems={'center'}>
            <motion.img
                initial={{ scale: 1 }}
                animate={{ scale: 1.5 }}
                transition={{ duration: props.duration/1000, ease: 'linear' }}
                src={props?.props.backgroundImage} alt={'Background'} className={styles.backgroundWrapper}/>
            <Stack direction={'column'} spacing={5} display={'grid'} justifyItems={'center'} className={styles.contentWrapper}>
                <Typography variant={'h3'} textAlign={'center'}>Der Stream startet gleich...</Typography>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ x: 200, opacity: 0 }}
                    transition={{ delay: 0.2, duration: 0.7, ease: [0,.13,.3,1] }}
                >
                    <Paper className={styles.infoPaper}>
                        <Box p={5}>
                            <Typography variant={'h5'} textAlign={'center'}>{mmss(props.countdown)}</Typography>
                        </Box>
                    </Paper>
                </motion.div>
            </Stack>
        </Box>
    )
}

export default StartLayout;
