import create from "zustand";
import {DefaultApolloClient} from "../../apollo/clients/apolloClients";
import {ALTER_SLIDE_MUTATION, CREATE_SLIDE_MUTATION, DELETE_SLIDE_MUTATION} from "../../apollo/queries/slide-queries";

export const useSlide = create(set => {

  return {
    createSlide: (showCountdown, props, duration, type, presId) => DefaultApolloClient.mutate({
      mutation: CREATE_SLIDE_MUTATION,
      variables: {showCountdown: showCountdown, props: props, duration: duration, type: type, presId: presId}
    }),
    alterSlide: (showCountdown, props, duration, pos, type, id) => DefaultApolloClient.mutate({
      mutation: ALTER_SLIDE_MUTATION,
      variables: {showCountdown: showCountdown, props: props, duration: duration, pos: pos, type: type, id: id}
    }),
    deleteSlide: (id) => DefaultApolloClient.mutate({
      mutation: DELETE_SLIDE_MUTATION,
      variables: {id: id}
    }),
  };
});
