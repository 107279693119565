import React, {useEffect, useState} from "react";
import {Box, Fab, Paper, Slider, Stack, Typography} from "@mui/material";
import {FaPause, FaPlay} from "react-icons/fa";
import {useCountdown} from "../../common/states/countdown-state";

const CountdownControls = () => {

    const [countdownDuration, setCountdownDuration] = useState(0)

    const {duration, start, stop, setDuration, isRunning} = useCountdown();

    useEffect(() => {
        if(!countdownDuration) {
            setCountdownDuration(duration/60)
        }
    }, [duration, countdownDuration])

    const handleCountdownDurationSliderChange = (event, newValue) => {
        setCountdownDuration(newValue);
    };

    return (
        <Paper>
            <Box p={1}>
                <Stack direction={'column'} spacing={1}>
                    <Box pt={1} px={1.8}>
                        <Typography variant={'body2'}>
                            Countdown: {countdownDuration} Minute(n)
                        </Typography>
                        <Box>
                            <Slider
                                onChangeCommitted={() => setDuration(countdownDuration*60)}
                                onChange={handleCountdownDurationSliderChange}
                                value={countdownDuration}
                                min={1}
                                max={60}
                            />
                        </Box>
                    </Box>
                    <Box px={1.8}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography sx={{ flexGrow: 1 }} variant={'body2'}>Countdown starten/stoppen</Typography>
                            <Fab onClick={() => isRunning ? stop() : start()} color={'primary'} size={'small'}>
                                {isRunning ? <FaPause/> : <FaPlay/>}
                            </Fab>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Paper>
    )
}

export default  CountdownControls
