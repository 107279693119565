import {useLogin} from "../../common/states/login-state";
import {Box, Button, Container, Paper, Stack, TextField, Typography} from "@mui/material";
import {useState} from "react";

export const LoginGate = ({children}) => {
  const {isLoggedIn, login, showError} = useLogin();
  const [pw, setPw] = useState('');

  return <>{isLoggedIn ? children :
    <Container maxWidth={'sm'}>
      <Box height={'100vh'} display={'grid'} alignItems={'center'} justifyContent={'center'}>
        <Paper>
          <Box p={1}>
            <Stack direction={'column'} spacing={1}>
              <Typography variant={'h1'}>
                Login
              </Typography>
             <Typography variant={'body1'}>
               Das über die URL mitgegebene Passwort (<i>?auth= ... </i>) ist leider falsch. Bitte ein gültiges Passwort
               eingeben:
             </Typography>
              <Stack direction={'row'} spacing={1}>
                <TextField fullWidth variant={'filled'} error={showError} helperText={showError && "Das eingegebene Passwort is falsch!"} type={"password"} label={'Passwort'} value={pw} onChange={e => setPw(e.target.value)} error={showError}/>
                <Button sx={{mt: 3}} variant={"contained"} color={"primary"} disabled={!pw} onClick={() => login(pw)}>Einloggen</Button>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Container>}</>;
}
