import {gql} from "@apollo/client";

export const NEXT_STREAMS_QUERY = gql`
query NextStreams($amount: Int!) {
  nextStreams(amount: $amount) {
    category {
      id
      name
      imageUrl
    }
    id
    isReccuring
    title
    startTime
    endTime
    canceledUntil
  }
}
`;
