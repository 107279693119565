import {ApolloClient, ApolloLink, concat, createHttpLink, InMemoryCache, split,} from '@apollo/client'
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {getMainDefinition} from "@apollo/client/utilities";
import create from "zustand";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const auth = urlParams.get('auth');

export const useLoginHeader = create(set => ({
  authHeader: auth || null,
  setAuthHeader: (authHeader) => set({authHeader})
}));

export const defaultHttpLink = createHttpLink({
  uri: '/gql',
})

const {protocol, host} = window.location;
export const wsLink = new GraphQLWsLink(
  createClient({
    url: `${protocol === 'https:' ? 'wss' : 'ws'}://${host}/gql`
  })
);

export const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: useLoginHeader.getState().authHeader,
    },
  })
  return forward(operation)
})

export const httpLink = concat(authMiddleware, defaultHttpLink);
export const splitLink = split(({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);
export const cache = new InMemoryCache()

export const DefaultApolloClient = new ApolloClient({
  link: splitLink,
  cache,
})
