import React from 'react';
import {Box} from "@mui/material";

const EmptyLayout = (props) => {

    return (
        <Box/>
    )
}

export default EmptyLayout;
