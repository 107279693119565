import React, {useEffect, useMemo, useState} from 'react';

import './style.module.css';
import Overlay from "./Overlay";
import {Box} from "@mui/material";
import styles from "./style.module.css";

import StreamsLayout from "./StreamsLayout";
import InfoLayout from "./InfoLayout";
import StartLayout from "./StartLayout";
import ChatLayout from "./ChatLayout";
import EmptyLayout from "./EmptyLayout";
import {AnimatePresence, motion} from "framer-motion";
import {useCountdown} from "../../common/states/countdown-state";
import {usePresentation} from "../../common/states/presentation-state";
import {useParams} from "react-router-dom";
import {slideTypes} from "./slideTypes";

import cratrLogo from "../../assets/img/cratr-logo.svg"

const getSlideComponent = (type, props = {}) => {
  switch (type) {
    case slideTypes.empty:
      return <EmptyLayout {...props} />;
    case slideTypes.streams:
      return <StreamsLayout {...props} />;
    case slideTypes.info:
      return <InfoLayout {...props} />;
    case slideTypes.chat:
      return <ChatLayout {...props} />
    default:
      return <StartLayout {...props} />
  }
}

const TemplateRouter = () => {
  const routeParams = useParams()

  const {presentations} = usePresentation();
  const currentPresentation = useMemo(() => presentations.filter(presentation => presentation.id === routeParams.id)[0], [presentations, routeParams.id]);

  return <Presentation presentation={currentPresentation}/>
}

export const Presentation = ({presentation}) => {
  const components = useMemo(() => {
    if (presentation && presentation.slides) return presentation.slides.map(slide => Object.assign(slide, {component: getSlideComponent(slide.type, slide.props)}));
    return [];
  }, [presentation]);

  const {seconds} = useCountdown();

  const [preload, setPreload] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (components?.length) {
      setPreload(true);
      const timeout = setTimeout(() => setPreload(false), 1500);
      return () => clearTimeout(timeout);
    } else if (preload) setPreload(false);
  }, [components]);

  useEffect(() => {
    if (components?.length && !preload) {
      const interval = setInterval(() => setCount(count === (components.length - 1) ? 0 : count + 1),
        components[count].duration * 1000, true);

      return () => clearInterval(interval);
    }
  }, [count, preload]);

  function AddExtraProps(Component, countdown, duration, props, logo, logoSize) {
    // eslint-disable-next-line react/jsx-pascal-case
    return <Component.type {...Component.props} countdown={countdown} duration={duration} props={props} logo={logo} logoSize={logoSize}/>;
  }

  return (<Box style={{overflow: "hidden"}}>
    {components?.length ?
      (<Box>
        <Overlay countdown={seconds} disableCountdown={components[count]?.showCountdown}
                 interval={components[count]?.duration * 1000} count={count} type={components[count]?.type}
                 preload={preload} logo={presentation?.logoUrl} logoSize={presentation?.logoSize}/>
        <Box className={styles.pageWrapper} style={{opacity: preload ? 0 : 1}}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={count}
              className={styles.componentWrapper}
              initial={{x: -200, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              exit={{x: 200, opacity: 0, transition: {ease: [.64,0,1,.72]}}}
              transition={{duration: 1, ease: [0,.13,.3,1]}}
            >
              {preload ?
                (<Box
                  sx={{opacity: 0}}>{components.map(c => AddExtraProps(c.component, seconds, 3000, c.props))}</Box>) :
                AddExtraProps(components[count].component, seconds, components[count].duration * 1000, components[count].props)}
            </motion.div>
          </AnimatePresence>
        </Box>
      </Box>) : undefined}
    <Box height={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <motion.div animate={{opacity: preload || !components.length ? 1 : 0}} style={{position: 'absolute'}}>
          <img height={'200px'} src={cratrLogo} alt={'CRATR Logo Preload'}/>
      </motion.div>
    </Box>
  </Box>);
}

export default TemplateRouter;
