import create from "zustand";
import {DefaultApolloClient} from "../../apollo/clients/apolloClients";
import {
  COUNTDOWN_QUERY,
  COUNTDOWN_SUBSCRIPTION,
  SET_COUNTDOWN_DURATION_MUTATION,
  START_COUNTDOWN_MUTATION, STOP_COUNTDOWN_MUTATION
} from "../../apollo/queries/countdown-queries";

export const useCountdown = create(set => {
  const status = {start: null, duration: 0};

  const watchQuery = DefaultApolloClient.watchQuery({query: COUNTDOWN_QUERY, fetchPolicy: "no-cache"});
  const subscription = DefaultApolloClient.subscribe({query: COUNTDOWN_SUBSCRIPTION, fetchPolicy: "no-cache"});
  watchQuery.startPolling(5000);

  const handleFn = value => {
    status.duration = value.duration;
    const start = value.startTime;
    status.start = (start && new Date(start).getTime()) || null;
  };

  watchQuery.subscribe(val => {
    handleFn(val.data.countdown);
  });
  subscription.subscribe(val => {
    if (!val.data.onCountdownChanges) return;
    handleFn(val.data.onCountdownChanges);
  });

  let left = 0;
  let running = false;
  setInterval(() => {
    let newLeft = status.duration;

    if (status.start) {
      const passed = Math.round((Date.now() - status.start) / 1000);
      newLeft = status.duration - passed;
    }
    if (newLeft !== left) set({seconds: (left = (newLeft <= 0 ? 0 : newLeft))});

    const newRunning = left !== 0 && status.start !== null;
    if (running !== newRunning) set({isRunning: (running = newRunning)});
    set({duration: status.duration})
  }, 80);

  return {
    seconds: 0,
    isRunning: false,
    duration: 0,
    start: () => DefaultApolloClient.mutate({mutation: START_COUNTDOWN_MUTATION}),
    stop: () => DefaultApolloClient.mutate({mutation: STOP_COUNTDOWN_MUTATION}),
    setDuration: (seconds) => DefaultApolloClient.mutate({
      mutation: SET_COUNTDOWN_DURATION_MUTATION,
      variables: {duration: seconds}
    }),
  };
});
