import React from 'react';

import styles from './style.module.css';
import {Box, Grid} from "@mui/material";

const ChatLayout = (props) => {

  return (<Box height={'100%'} display={'grid'} alignContent={'center'}>
    <Grid container spacing={5} className={styles.wrapper}>
      <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Box>
          {props?.props.leftImage && <img src={props?.props.leftImage} alt={'Vordergrundbild'} width={'600px'}/>}
        </Box>
      </Grid>
      <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>

      </Grid>
      <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Box>
          {props?.props.rightImage && <img src={props?.props.rightImage} alt={'Vordergrundbild'} width={'600px'}/>}
        </Box>
      </Grid>
    </Grid>
  </Box>)
}

export default ChatLayout;
