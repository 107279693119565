import create from "zustand";
import {DefaultApolloClient} from "../../apollo/clients/apolloClients";
import {
  ALTER_PRESENTATION_LOGO_SIZE_MUTATION,
  ALTER_PRESENTATION_LOGO_URL_MUTATION,
  ALTER_PRESENTATION_NAME_MUTATION,
  CREATE_PRESENTATION_MUTATION, DELETE_PRESENTATION_MUTATION,
  PRESENTATION_QUERY
} from "../../apollo/queries/presentation-queries";

export const usePresentation = create(set => {

  const watchQuery = DefaultApolloClient.watchQuery({query: PRESENTATION_QUERY, fetchPolicy: "no-cache"});

  watchQuery.subscribe(val => {
    set({presentations: val.data.presentations})
  });

  return {
    presentations: [],
    refetchPresentations: () => watchQuery.refetch(),
    createPresentation: (name) => DefaultApolloClient.mutate({
      mutation: CREATE_PRESENTATION_MUTATION,
      variables: {name: name}
    }).then(() => watchQuery.refetch()),
    alterPresentationName: (name, id) => DefaultApolloClient.mutate({
      mutation: ALTER_PRESENTATION_NAME_MUTATION,
      variables: {name: name, id: id}
    }).then(() => watchQuery.refetch()),
    alterPresentationLogoUrl: (id, logoUrl) => DefaultApolloClient.mutate({
      mutation: ALTER_PRESENTATION_LOGO_URL_MUTATION,
      variables: {id: id, logoUrl: logoUrl}
    }).then(() => watchQuery.refetch()),
    alterPresentationLogoSize: (id, logoSize) => DefaultApolloClient.mutate({
      mutation: ALTER_PRESENTATION_LOGO_SIZE_MUTATION,
      variables: {id: id, logoSize: logoSize}
    }).then(() => watchQuery.refetch()),
    deletePresentation: (id) => DefaultApolloClient.mutate({
      mutation: DELETE_PRESENTATION_MUTATION,
      variables: {id: id}
    }).then(() => watchQuery.refetch()),
  };
});
