import {gql} from "@apollo/client";

export const COUNTDOWN_FRAGMENT = gql`
    fragment CountdownFragment on Countdown {
        duration
        startTime
    }
`;

export const COUNTDOWN_QUERY = gql`
    ${COUNTDOWN_FRAGMENT}
    query Countdown {
        countdown {...CountdownFragment}
    }
`;

export const START_COUNTDOWN_MUTATION = gql`
    ${COUNTDOWN_FRAGMENT}
    mutation StartCountdown {
        startCountdown {...CountdownFragment}
    }
`;

export const STOP_COUNTDOWN_MUTATION = gql`
    ${COUNTDOWN_FRAGMENT}
    mutation StopCountdown {
        stopCountdown {...CountdownFragment}
    }
`;

export const SET_COUNTDOWN_DURATION_MUTATION = gql`
    ${COUNTDOWN_FRAGMENT}
    mutation SetCountdownDuration($duration: Int!) {
        setCountdownDuration(duration: $duration) {...CountdownFragment}
    }
`;

export const COUNTDOWN_SUBSCRIPTION = gql`
    ${COUNTDOWN_FRAGMENT}
    subscription Countdown {
        onCountdownChanges {...CountdownFragment}
    }
`;
