import React from "react";
import {Box, Paper} from "@mui/material";
import SlideMask from "./SlideMask";

const AddSlide = (props) => {
    return (
        <Paper>
            <Box p={1}>
                <SlideMask edit={false} slide={null} presId={props.presId}/>
            </Box>
        </Paper>
    )
}

export default AddSlide
