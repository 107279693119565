import {gql} from "@apollo/client";

export const PRESENTATION_FRAGMENT = gql`
    fragment PresentationFragment on Presentation {
        id
        name
        logoUrl
        logoSize
        slides {
           id
           type
           pos
           duration
           showCountdown
           props 
        }
    }
`;

export const PRESENTATION_QUERY = gql`
    ${PRESENTATION_FRAGMENT}
    query Presentations {
      presentations {...PresentationFragment}
    }
`;

export const CREATE_PRESENTATION_MUTATION = gql`
    ${PRESENTATION_FRAGMENT}
    mutation CreatePresentation($name: String!) {
      createPresentation(name: $name) {...PresentationFragment}
    }
`;

export const ALTER_PRESENTATION_NAME_MUTATION = gql`
    ${PRESENTATION_FRAGMENT}
    mutation AlterPresentationName($alterPresentationNameName2: String!, $alterPresentationNameId: String!) {
      alterPresentationName(name: $alterPresentationNameName2, id: $alterPresentationNameId) {...PresentationFragment}
    }
`;

export const ALTER_PRESENTATION_LOGO_URL_MUTATION = gql`
    ${PRESENTATION_FRAGMENT}
    mutation AlterPresentationLogoUrl($id: String!, $logoUrl: String) {
      alterPresentationLogoUrl(id: $id, logoUrl: $logoUrl) {...PresentationFragment}
    }
`;

export const ALTER_PRESENTATION_LOGO_SIZE_MUTATION = gql`
    ${PRESENTATION_FRAGMENT}
    mutation AlterPresentationLogoSize($id: String!, $logoSize: Float!) {
      alterPresentationLogoSize(id: $id, logoSize: $logoSize) {...PresentationFragment}
    }
`;

export const DELETE_PRESENTATION_MUTATION = gql`
    mutation DeletePresentation($id: String!) {
      deletePresentation(id: $id)
    }
`;
