import React, {useEffect, useState} from 'react';

import styles from './style.module.css';
import {
  Avatar,
  Box, Button,
  Container,
  Grid, IconButton,
  Paper, Slider,
  Stack, styled, TextField,
  Typography
} from "@mui/material";
import {AiOutlineArrowRight} from "react-icons/ai";
import {IoIosAdd, IoMdClose} from "react-icons/io";
import SlideMask from "./SlideMask";
import CountdownControls from "./CountdownControls";
import Presentations from "./Presentations";
import AddSlide from "./AddSlide";
import {usePresentation} from "../../common/states/presentation-state";
import {useSlide} from "../../common/states/slide-state";
import PopoverComponent from "../../common/popover/PopoverComponent";
import {LoginGate} from "./LoginGate";

const SettingsPanel = () => {

  const [logoSize, setLogoSize] = useState(1)
  const [activePresentation, setActivePresentation] = useState(null)
  const [activeSlide, setActiveSlide] = useState(null)
  const [presentationLogo, setPresentationLogo] = useState(null)

  const {presentations, refetchPresentations, alterPresentationLogoUrl, alterPresentationLogoSize} = usePresentation()
  const {deleteSlide} = useSlide()

  useEffect(() => {
    setActiveSlide(null)
    setActivePresentation(presentations.filter(presentation => presentation?.id === activePresentation?.id)[0])
    setLogoSize(presentations.filter(presentation => presentation?.id === activePresentation?.id)[0]?.logoSize || 1)
  }, [activePresentation, presentations])

  const StyledAvatar = styled(Avatar)(({theme}) => ({
    background: "#202524",
    color: '#ffffff'
  }))

  const handleDeleteSlide = (id) => {
    deleteSlide(id).then(() => {
      refetchPresentations()
    })
  }

  const renderPresentationSlides = () => {
    return activePresentation?.slides.map((slide, index, array) => (
      <Grid key={index} item xs={6}>
        <Stack direction={'row'} spacing={0} height={'100%'} position={'relative'}>
          <Paper className={activeSlide?.id === slide?.id ? styles.activePaper : styles.slidePaper}
                 sx={{width: '-webkit-fill-available'}} onClick={() => setActiveSlide(slide)}>
            <Box className={styles.deleteButton}>
              <IconButton onClick={() => handleDeleteSlide(slide.id)} color={'secondary'}>
                <IoMdClose/>
              </IconButton>
            </Box>
            <Box px={4} py={2}>
              <Stack direction={'column'} spacing={1}>
                <Typography variant={'h2'}>{slide.type}</Typography>
                <Typography variant={'body1'}>{slide.duration + " Sekunde(n)"}</Typography>
                <Typography
                  variant={'body1'}>Countdown: {slide.showCountdown ? "anzeigen" : "nicht anzeigen"}</Typography>
              </Stack>
            </Box>
          </Paper>
          <Box
            className={styles.arrowWrapper}
            height={'100%'}
            display={'grid'}
            justifyItems={'center'}
            alignItems={'center'}
          >
            {index + 1 !== array.length ?
              <StyledAvatar className={styles.arrow}>
                <AiOutlineArrowRight/>
              </StyledAvatar>
              :
              <PopoverComponent size={450} className={styles.arrow} icon={<IoIosAdd className={styles.addButtonText}/>}>
                <AddSlide presId={activePresentation.id}/>
              </PopoverComponent>
            }
          </Box>
        </Stack>
      </Grid>
    ))
  }

  const renderActiveSlide = () => {
    return (
      <Paper>
        <Box p={1}>
          <SlideMask edit={true} slide={activeSlide}/>
        </Box>
      </Paper>
    )
  }

  const handleLogoSizeSlider = (event, newValue) => {
    setLogoSize(newValue);
  };

  const renderDuration = () => {
    return (
      <Box pt={1} px={1.8}>
        <Typography variant={'body2'}>
          Größe: {logoSize}
        </Typography>
        <Box>
          <Slider
            onChange={handleLogoSizeSlider}
            onChangeCommitted={() => alterPresentationLogoSize(activePresentation.id, logoSize)}
            value={logoSize}
            min={0}
            max={2}
            step={0.1}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Container maxWidth={'xl'} className={styles.pageContainer}>
      <LoginGate>
        <Box py={5} height={'100%'} display={'flex'} justifyContent={'center'}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack direction={"column"} spacing={1}>
                <Typography variant={'h1'}>Präsentationen</Typography>
                <CountdownControls/>
                <Paper>
                  <Box p={1}>
                    <Presentations onSetActivePresentation={(value) => {
                      setActivePresentation(value)
                      setPresentationLogo(value.logoUrl || "")
                      setLogoSize(value.logoSize || 1)
                    }} active={activePresentation}/>
                  </Box>
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction={'column'} spacing={1}>
                <Typography variant={'h1'}>Folien</Typography>
                {!activePresentation ?
                  <Paper>
                    <Box p={1}>
                      <Typography variant={'body1'}>
                        Keine Präsentation ausgewählt.
                      </Typography>
                    </Box>
                  </Paper>
                  :
                  <Stack direaction={'column'} spacing={1}>
                    <Paper>
                      <Box p={1}>
                        <Grid container spacing={1} alignItems={'center'}>
                          <Grid item xs={9}>
                            <TextField value={presentationLogo}
                                       onChange={(event) => setPresentationLogo(event.target.value)} size={'small'}
                                       fullWidth variant={'filled'} label={'Logo Url'}/>
                          </Grid>
                          <Grid item xs={3} textAlign={'right'}>
                            <Button onClick={() => alterPresentationLogoUrl(activePresentation.id, presentationLogo)}
                                    fullWidth className={styles.formButton} variant={'contained'} color={'primary'}>
                              Speichern
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            {renderDuration()}
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                    <Box>
                      <Grid container spacing={1}>
                        {activePresentation?.slides.length !== 0 ?
                          renderPresentationSlides()
                          :
                          <Grid item xs={12}>
                            <AddSlide presId={activePresentation.id}/>
                          </Grid>
                        }
                      </Grid>
                    </Box>
                  </Stack>
                }
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction={'column'} spacing={1}>
                <Typography variant={'h1'}>Folie anpassen</Typography>
                {!activeSlide ?
                  <Paper>
                    <Box p={1}>
                      <Typography variant={'body1'}>
                        Keine Folie ausgewählt.
                      </Typography>
                    </Box>
                  </Paper>
                  :
                  <Box>
                    {renderActiveSlide()}
                  </Box>
                }
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </LoginGate>
    </Container>
  )
}

export default SettingsPanel;
