import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme/theme";
import TemplateRouter from "./views/Templates";
import 'moment/locale/de'
import moment from 'moment'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import SettingsPanel from "./views/Settings/SettingsPanel";

import {
    authMiddleware,
    DefaultApolloClient,
    defaultHttpLink,
} from './apollo/clients/apolloClients'
import { ApolloProvider, concat } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import {useState} from "react";
import NetworkError from "./common/errors";



function App() {

    const [networkError, setNetworkError] = useState(null)
    moment.locale('de');

    const onErrorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                console.log(err)
            }
        }

        // To retry on network errors, we recommend the RetryLink
        // instead of the onError link. This just logs the error.
        if (networkError) {
            console.log(`[Network error]: ${networkError}`)
            setNetworkError(networkError)
        }
    })

    const defaultLink = concat(
        authMiddleware,
        onErrorLink.concat(defaultHttpLink)
    )
    DefaultApolloClient.setLink(defaultLink)



    return (
        <ApolloProvider client={DefaultApolloClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
              <BrowserRouter>
                  {networkError ? (
                      <NetworkError errors={networkError} />
                  ) : (
                      <Routes>
                          <Route path="/:id" element={<TemplateRouter/>}/>
                          <Route path="/settings" element={<SettingsPanel/>}/>
                      </Routes>
                  )}
              </BrowserRouter>
          </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
