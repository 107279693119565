import {gql} from "@apollo/client";

export const SLIDE_FRAGMENT = gql`
    fragment SlideFragment on Slide {
       id
       type
       pos
       duration
       showCountdown
       props 
    }
`;

export const CREATE_SLIDE_MUTATION = gql`
    ${SLIDE_FRAGMENT}
    mutation CreateSlide($props: JSONObject!, $duration: Int!, $type: String!, $presId: String!, $showCountdown: Boolean) {
      createSlide(props: $props, duration: $duration, type: $type, presId: $presId, showCountdown: $showCountdown) {...SlideFragment}
    }
`;

export const ALTER_SLIDE_MUTATION = gql`
    ${SLIDE_FRAGMENT}
    mutation AlterSlide($id: String!, $type: String, $pos: Int, $duration: Int, $props: JSONObject, $showCountdown: Boolean) {
      alterSlide(id: $id, type: $type, pos: $pos, duration: $duration, props: $props, showCountdown: $showCountdown) {...SlideFragment}
    }
`;

export const DELETE_SLIDE_MUTATION = gql`
    mutation DeleteSlide($id: String!) {
      deleteSlide(id: $id)
    }
`;
