import React, {useEffect, useState} from "react";
import {slideTypes} from "../Templates/slideTypes";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack, Switch,
    TextField,
    Typography
} from "@mui/material";
import {useSlide} from "../../common/states/slide-state";
import {usePresentation} from "../../common/states/presentation-state";
import {BsCheck2, BsFillFileTextFill, BsImage} from "react-icons/bs";
import styles from "./style.module.css"

const SlideMask = (props) => {

    const presId = props?.presId
    const id = props.slide?.id

    const [type, setType] = useState(slideTypes.info)
    const [pos, setPos] = useState(0)
    const [duration, setDuration] = useState(30)
    const [streamCount, setStreamCount] = useState(3)
    const [headline, setHeadline] = useState("")
    const [text, setText] = useState("")
    const [showCountdown, setShowCountdown] = useState(true)
    const [leftImage, setLeftImage] = useState("")
    const [rightImage, setRightImage] = useState("")
    const [foregroundImage, setForegroundImage] = useState("")
    const [backgroundImage, setBackgroundImage] = useState("")
    const [invertLayout, setInvertLayout] = useState(false)

    const {createSlide, alterSlide} = useSlide()
    const {refetchPresentations} = usePresentation()

    useEffect(() => {
        setPos(props.slide?.pos)
        setType(props.slide?.type || slideTypes.info)
        setDuration(props.slide?.duration || 30)
        setStreamCount(props.slide?.props.streamCount || 3)
        setHeadline(props.slide?.props.headline)
        setText(props.slide?.props.text)
        setShowCountdown(props.slide?.showCountdown)
        setForegroundImage(props.slide?.props.foregroundImage)
        setLeftImage(props.slide?.props.leftImage)
        setRightImage(props.slide?.props.rightImage)
        setBackgroundImage(props.slide?.props.backgroundImage)
        setInvertLayout(props.slide?.props.invertLayout)
    }, [props.slide])

    const marks = [
        {
            value: 0,
        },
        {
            value: 30,
        },
        {
            value: 60,
        },
        {
            value: 90,
        },
        {
            value: 120,
        }
    ];

    const handleDurationSliderChange = (event, newValue) => {
        setDuration(newValue);
    };

    const handleStreamsSliderChange = (event, newValue) => {
        setStreamCount(newValue);
    };

    const renderTypes = () => {
        let typeArray = []
        for (const key in slideTypes) {
            typeArray.push(slideTypes[key])
        }
        return typeArray.map((type, index) => (
            <MenuItem key={index} value={type}>{type}</MenuItem>
        ))
    }

    const renderDuration = () => {
        return (
            <Box pt={1} px={1.8}>
                <Typography variant={'body2'}>
                    Laufzeit: {duration} Sekunden
                </Typography>
                <Box>
                    <Slider
                        onChange={handleDurationSliderChange}
                        value={duration}
                        min={5}
                        max={120}
                        marks={marks}
                    />
                </Box>
            </Box>
        )
    }

    const renderStreamCount = () => {
        return (
            <Box pt={1} px={1.8}>
                <Typography variant={'body2'}>
                    Angezeigte Streams: {streamCount}
                </Typography>
                <Box>
                    <Slider
                        onChange={handleStreamsSliderChange}
                        value={streamCount}
                        min={1}
                        max={4}
                    />
                </Box>
            </Box>
        )
    }

    const renderInfo = () => {
        return (
            <Stack direction={'column'} spacing={1}>
                <TextField variant={'filled'} label={'Überschrift'} value={headline} onChange={(event) => setHeadline(event.target.value)}/>
                <TextField multiline rows={5} variant={'filled'} label={'Text'} value={text} onChange={(event) => setText(event.target.value)}/>
            </Stack>
        )
    }

    const renderCountdownSwitch = () => {
        return (
            <Box px={1.8}>
                <Stack direction={'row'} alignItems={'center'}>
                    <Typography sx={{ flexGrow: 1 }} variant={'body2'}>Countdown im Overlay</Typography>
                    <Switch checked={showCountdown} onClick={() => setShowCountdown(!showCountdown)} />
                </Stack>
            </Box>
        )
    }

    const renderInvertLayoutSwitch = () => {
        return (
            <Box px={1.8}>
                <Typography mb={1} variant={'body2'}>Layout Richtung</Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Button disabled={!invertLayout} fullWidth variant={'contained'} color={'primary'} onClick={() => setInvertLayout(false)}>
                        {!invertLayout && <Box className={styles.check}>
                            <BsCheck2/>
                        </Box>}
                        <Stack px={2} py={1} direction={'row'} spacing={2}>
                            <Stack direction={'column'} spacing={1}>
                                <BsImage style={{ fontSize: "2rem" }}/>
                            </Stack>
                            <Stack direction={'column'} spacing={1}>
                                <BsFillFileTextFill style={{ fontSize: "2rem" }}/>
                            </Stack>
                        </Stack>
                    </Button>
                    <Button disabled={invertLayout} fullWidth variant={'contained'} color={'primary'} onClick={() => setInvertLayout(true)}>
                        {invertLayout && <Box className={styles.check}>
                            <BsCheck2/>
                        </Box>}
                        <Stack px={2} py={1} direction={'row'} spacing={2}>
                            <Stack direction={'column'} spacing={1}>
                                <BsFillFileTextFill style={{ fontSize: "2rem" }}/>
                            </Stack>
                            <Stack direction={'column'} spacing={1}>
                                <BsImage style={{ fontSize: "2rem" }}/>
                            </Stack>
                        </Stack>
                    </Button>

                    {/*
                        <Typography sx={{ flexGrow: 1 }} variant={'body2'}>Layout Invertieren</Typography>
                        <Switch checked={invertLayout} onClick={() => setInvertLayout(!invertLayout)} />
                    */}
                </Stack>
            </Box>
        )
    }

    const renderLeftImage = () => {
        return (
            <TextField variant={'filled'} label={'Linkes Bild'} value={leftImage} onChange={(event) => setLeftImage(event.target.value)}/>
        )
    }

    const renderRightImage = () => {
        return (
            <TextField variant={'filled'} label={'Rechtes Bild'} value={rightImage} onChange={(event) => setRightImage(event.target.value)}/>
        )
    }

    const renderForegroundImage = () => {
        return (
            <TextField variant={'filled'} label={'Vordergrund Bild'} value={foregroundImage} onChange={(event) => setForegroundImage(event.target.value)}/>
        )
    }

    const renderBackgroundImage = () => {
        return (
            <TextField variant={'filled'} label={'Hintergrund Bild'} value={backgroundImage} onChange={(event) => setBackgroundImage(event.target.value)}/>
        )
    }

    const renderComponents = (type) => {
        switch(type) {
            case slideTypes.empty:
                return (
                    <Stack direction={'column'} spacing={1}>
                        {renderCountdownSwitch()}
                        {renderDuration()}
                    </Stack>
                )
            case slideTypes.info:
                return (
                    <Stack direction={'column'} spacing={1}>
                        {renderCountdownSwitch()}
                        {renderInvertLayoutSwitch()}
                        {renderDuration()}
                        {renderForegroundImage()}
                        {renderBackgroundImage()}
                        {renderInfo()}
                    </Stack>
                )
            case slideTypes.countdown:
                return (
                    <Stack direction={'column'} spacing={1}>
                        {renderCountdownSwitch()}
                        {renderDuration()}
                        {renderBackgroundImage()}
                    </Stack>
                )
            case slideTypes.streams:
                return (
                    <Stack direction={'column'} spacing={1}>
                        {renderCountdownSwitch()}
                        {renderDuration()}
                        {renderStreamCount()}
                    </Stack>
                )
            case slideTypes.chat:
                return (
                    <Stack direction={'column'} spacing={1}>
                        {renderCountdownSwitch()}
                        {renderDuration()}
                        {renderLeftImage()}
                        {renderRightImage()}
                    </Stack>
                )
            default:
                return false
        }
    }

    const handleEditSlide = () => {
        let props = {}
        if(headline) props.headline = headline
        if(text) props.text = text
        if(leftImage) props.leftImage = leftImage
        if(rightImage) props.rightImage = rightImage
        if(foregroundImage) props.foregroundImage = foregroundImage
        if(backgroundImage) props.backgroundImage = backgroundImage
        if(streamCount) props.streamCount = streamCount
        if(invertLayout) props.invertLayout = invertLayout

        alterSlide(showCountdown, props, duration, pos, type, id).then(() => {
            refetchPresentations()
        })
    }

    const handleAddSlide = () => {
        let props = {}
        if(headline) props.headline = headline
        if(text) props.text = text
        if(leftImage) props.leftImage = leftImage
        if(rightImage) props.rightImage = rightImage
        if(foregroundImage) props.foregroundImage = foregroundImage
        if(backgroundImage) props.backgroundImage = backgroundImage
        if(streamCount) props.streamCount = streamCount
        if(invertLayout) props.invertLayout = invertLayout

        createSlide(showCountdown, props, duration, type, presId).then(() => {
            refetchPresentations()
        })
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <FormControl fullWidth variant={'filled'} >
                <InputLabel shrink id="typ">Typ</InputLabel>
                <Select
                    value={type}
                    onChange={(event) => setType(event.target.value)}
                    labelId="typ"
                    label="Typ"
                >
                    {renderTypes()}
                </Select>
            </FormControl>
            {renderComponents(type)}
            <Button onClick={() => props.edit ? handleEditSlide() : handleAddSlide()} variant={'contained'} color={'primary'}>{props.edit ? "Speichern" : "Hinzufügen"}</Button>
        </Stack>
    )
}

export default SlideMask
