import React, {useEffect, useMemo, useState} from 'react';

import styles from './style.module.css';
import {Box, Typography} from "@mui/material";
import pandorLogo from '../../../assets/img/pandor_logo.png'
import {AnimatePresence, motion} from 'framer-motion'
import {mmss} from "../../../time";
import create from "zustand";
import {slideTypes} from "../slideTypes";

const makeIframe = (channel) => <iframe scrolling="no" title={'twitch chat'} className={styles.chatFrame}
                                        src={`https://showmy.chat/c/${encodeURIComponent(channel)}?theme=peruvian-idol`}/>;
export const useChatIframe = create(set => ({
  element: makeIframe('pandorsaurus'), setChannel: channel => set({element: makeIframe(channel)})
}))

const Overlay = (props) => {

  const [timeBar, setTimebar] = useState(undefined)

  const {element} = useChatIframe();

  useEffect(() => {
    setTimebar(
      <AnimatePresence>
        <motion.div
          key={props.count}
          className={styles.timeBar}
          initial={{width: '100%'}}
          animate={{width: '0%'}}
          transition={{duration: props.interval / 1000, ease: "linear"}}
        />
      </AnimatePresence>
    )
  }, [props.count, props.interval])

  const isChat = props.type === slideTypes.chat && !props.preload;
  const chatAnimation = useMemo(() => ({scale: isChat ? 1 : 0.8, opacity: isChat ? 1 : 0}), [isChat]);
  const chatInEasing = [.46, .2, .42, .96];
  const chatOutEasing = [.65, .01, 1, .79];
  const chatTransition = useMemo(() => ({
    ease: isChat ? chatInEasing : chatOutEasing,
    delay: isChat ? .3 : 0,
    duration: isChat ? 1.3 : 0.3,
  }), [isChat]);

  return (
    <motion.div animate={{opacity: props.preload ? 0 : 1}} initial={{opacity: 0}} transition={{duration: 0.2}}>
      <Box className={styles.overlayContainer} height={'100%'} width={'100%'}>
        <Box p={5} className={styles.logoWrapper}>
          <img alt={'Logo'} src={props.logo || pandorLogo} height={'120px'} style={{ transform: `scale(${props.logoSize})` }}/>
        </Box>
        <motion.div animate={chatAnimation}
                    transition={chatTransition}
                    style={{width: '100%', height: '100%', position: 'absolute', left: 0, top: 0}}>
          <Box p={5} height={'100%'} width={'100%'}>
            <Box className={styles.chatWrapper}>
              {element}
            </Box>
          </Box>
        </motion.div>
        <AnimatePresence exitBeforeEnter>
          {props.disableCountdown &&
            <motion.div
              initial={{y: -200, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -200, opacity: 0}}
              transition={{type: "spring", stiffness: 100, mass: 0.3}}
            >
              <Box p={5} className={styles.countdownWrapper}>
                <Typography variant={'h3'}>{mmss(props.countdown)}</Typography>
              </Box>
            </motion.div>

          }
        </AnimatePresence>
        {!props.preload && timeBar}
      </Box>
    </motion.div>
  )
}

export default Overlay;
