import React from 'react';

import styles from './style.module.css';
import {Box, Grid, Paper, Stack, Typography} from "@mui/material";
import {motion} from 'framer-motion'

const InfoLayout = (props) => {

    return(
        <Box height={'100%'} display={'grid'} alignContent={'center'}>
            <motion.img
                initial={{ scale: 1 }}
                animate={{ scale: 1.5 }}
                transition={{ duration: props.duration/1000, ease: 'linear' }}
                src={props?.props.backgroundImage} alt={'GDZ Background'} className={styles.backgroundWrapper}/>
            <Grid container spacing={0} className={styles.wrapper} direction={props?.props.invertLayout ? "row-reverse" : "row"}>
                <Grid item xs={6}>
                    <Box p={8} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={props?.props.invertLayout ? 'flex-start' : 'flex-end'} className={styles.infoBoxWrapper}>
                        {props?.props.foregroundImage && <img src={props?.props.foregroundImage} alt={'Vordergrundbild'} width={'600px'}/>}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.7, ease: [0,.13,.3,1] }}
                    >
                        <Box p={20} className={styles.infoBoxWrapper}>
                            <Paper className={styles.infoPaper}>
                                <Box p={5}>
                                    <Stack direction={'column'} spacing={5}>
                                        <Typography variant={'h4'}>{props?.props.headline}</Typography>
                                        <Typography variant={'h2'}>{props?.props.text}</Typography>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Box>
                    </motion.div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default InfoLayout;
