import React, { useEffect } from 'react'
import Popover from '@mui/material/Popover'
import {
    usePopupState,
    bindTrigger,
    bindPopover,
} from 'material-ui-popup-state/hooks'
import { Box, Fab, Tooltip } from '@mui/material'
import { useGlobalStore } from '../../store'

const PopoverComponent = (props) => {
    const popupState = usePopupState({
        variant: 'popper',
        popupId: 'contentPopover',
        disableAutoFocus: false,
    })

    //const globalPopupState = useGlobalStore((state) => state.popupState)
    const setGlobalPopupState = useGlobalStore((state) => state.setPopupState)

    useEffect(() => {
        if (popupState.anchorEl && !props?.ignoreGlobal) {
            setGlobalPopupState(popupState)
        }
    }, [popupState, props?.ignoreGlobal, setGlobalPopupState])

    return (
        <Box onClick={() => !popupState.isOpen && props.onClick()}>
            <Tooltip
                followCursor
                title={props.tooltip ? props.tooltip : ''}
                placement='top'
            >
                {props.content ? (
                    <Box sx={{ cursor: 'pointer' }} {...bindTrigger(popupState)}>
                        {props.content}
                    </Box>
                ) : (
                    <Fab
                        disabled={props?.disabled}
                        className={props?.className}
                        {...bindTrigger(popupState)}
                        size={'small'}
                        color={props.color ? props.color : 'primary'}
                        sx={{ boxShadow: 'none' }}
                    >
                        {props.icon}
                    </Fab>
                )}
            </Tooltip>
            <Popover
                sx={{ maxHeight: props?.maxHeight }}
                onClick={(event) => event.preventDefault()}
                BackdropProps={{ invisible: false }}
                elevation={0}
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: props.anchor ? props.anchor : 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: props.anchor ? props.anchor : 'center',
                }}
            >
                <Box sx={{ width: props?.size }} p={1}>
                    {props.children}
                </Box>
            </Popover>
        </Box>
    )
}

export default PopoverComponent
