import create from "zustand";
import {DefaultApolloClient, useLoginHeader} from "../../apollo/clients/apolloClients";
import {LOGIN_QUERY} from "../../apollo/queries/login-query";

export const useLogin = create(set => {
  let showError = false;

  const query = () => DefaultApolloClient.query({query: LOGIN_QUERY}).then(res => {
    const isLoggedIn = res.data?.isAuthenticated === true;
    set({isLoggedIn, showError});
    showError = false;
  }).catch(e => {
    set({isLoggedIn: false, showError});
    showError = false;
  });

  query().then();

  return {
    isLoggedIn: false, showError: false, login: (pw) => {
      useLoginHeader.getState().setAuthHeader(pw);
      showError = true;
      query().then();
    }
  };
});
