import React from 'react'
import styles from './style.module.css';
import { Box, Stack, Typography } from '@mui/material'
import { BiErrorAlt } from 'react-icons/bi'

const NetworkError = () => {
    return (
        <Box
            component='main'
            height={'100vh'}
            display={'grid'}
            alignContent={'center'}
            justifyContent={'center'}
            position={'relative'}
        >
            <Box p={1}>
                <Stack direction={'row'} spacing={3} alignItems={'center'}>
                    <BiErrorAlt className={styles.errorIcon} />
                    <Stack
                        direction={'column'}
                        position={'relative'}
                        sx={{ zIndex: '10' }}
                    >
                        <Typography component={'span'} variant={'h1'}>
                            Fehler...
                        </Typography>
                        <Typography component={'span'} variant={'body1'}>
                            Ein Fehler ist aufgetreten. Laden Sie die Seite neu, <br />
                            oder kontaktieren Sie eine Administrationsebene.
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default NetworkError
