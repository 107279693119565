import React, {useState} from "react";
import {Box, Button, Fab, Grid, List, ListItem, ListItemText, Stack, TextField, Typography} from "@mui/material";
import styles from "./style.module.css";
import {usePresentation} from "../../common/states/presentation-state";
import {IoMdClose} from "react-icons/io";
import {FiLink} from "react-icons/fi";

const Presentations = (props) => {

    const [presentationName, setPresentationName] = useState("")
    const [loading, setLoading] = useState(false)

    const {presentations, createPresentation, deletePresentation, refetchPresentations} = usePresentation()

    const handleAddPresentation = () => {
        setLoading(true)
        createPresentation(presentationName).then(() => {
            setPresentationName("")
            setLoading(false)
            refetchPresentations()
        })
    }

    const handleDeletePresentation = (id) => {
        deletePresentation(id).then(() => {
            refetchPresentations()
        })
    }

    const renderPresentations = () => {
        return presentations.map((presentation, index) => (
            <ListItem
                className={presentation.id === props.active?.id ? styles.activeListItem : styles.listItem}
                key={index}
                onClick={() => {
                    props.onSetActivePresentation(presentation)
                }}
                secondaryAction={
                    <Stack direction={'row'} spacing={1}>
                        <Fab onClick={() => navigator.clipboard.writeText(window.location.origin+"/"+presentation.id)} size={'small'} color={'primary'} edge="end" aria-label="delete">
                            <FiLink/>
                        </Fab>
                        <Fab onClick={() => handleDeletePresentation(presentation.id)} size={'small'} color={'secondary'} edge="end" aria-label="delete">
                            <IoMdClose/>
                        </Fab>
                    </Stack>
                }
            >
                <ListItemText
                    primary={presentation.name}
                    secondary={
                    <Typography>
                        {presentation.slides.length + " Folie(n)"}
                    </Typography>}
                />
            </ListItem>
        ))
    }

    return (
        <Stack direction={'column'} spacing={1}>
            <List dense style={{ padding: "0px" }}>
                {renderPresentations()}
            </List>
            <Box>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={9}>
                        <TextField disabled={loading} value={presentationName} onChange={(event) => setPresentationName(event.target.value)} size={'small'} fullWidth variant={'filled'} label={'Name'}/>
                    </Grid>
                    <Grid item xs={3} textAlign={'right'}>
                        <Button disabled={loading} onClick={() => handleAddPresentation()} fullWidth className={styles.formButton} variant={'contained'} color={'primary'}>
                            Erstellen
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}

export default Presentations
